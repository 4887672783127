import { useEffect, useState } from 'react'

const BREAKPOINT = 767

export default (breakpoint = BREAKPOINT) => {
  const [isMobile, setIsMobile] = useState(undefined)

  useEffect(() => {
    function onResize() {
      if (typeof isMobile === 'undefined') {
        setIsMobile(window.innerWidth < breakpoint)
      } else {
        if (isMobile === false && window.innerWidth < breakpoint) {
          setIsMobile(true)
        }
        if (isMobile === true && window.innerWidth >= breakpoint) {
          setIsMobile(false)
        }
      }
    }

    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [isMobile])

  return isMobile
}
