import React from 'react'
import { handleImgixUrl } from '@kogk/common'
import Img from 'gatsby-image'
import styles from './HeroSlider.module.scss'
import { Link } from '@kogk/gatsby-theme-base'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import useIsMobile from '@root/src/hooks/useIsMobile'
import cn from 'classnames'

export default ({ slides }) => {
  const isMobile = useIsMobile(576)

  return (
    <div className={styles.carouselWrapper}>
      <span className={cn('no-style', styles.arrowBtn)}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </span>
      <Carousel
        showStatus={false}
        showThumbs={false}
        interval={4000}
        useKeyboardArrows={false}
        infiniteLoop
        autoPlay
        renderIndicator={false}
      >
        {slides?.length &&
          slides.map((slide, key) => {
            return (
              <Link to={slide.link} key={key} target='_self'>
                {isMobile === false && (
                  <Img
                    className='d-none d-sm-block'
                    fluid={handleImgixUrl(slide.image).gatsbyImageFluid()}
                  />
                )}
                {isMobile === true && (
                  <Img
                    className='d-block d-sm-none'
                    fluid={handleImgixUrl(slide.mobileImage).gatsbyImageFluid()}
                  />
                )}
              </Link>
            )
          })}
      </Carousel>

      <span className={cn('no-style', styles.arrowBtn)}>
        <FontAwesomeIcon icon={faAngleRight} />
      </span>
    </div>
  )
}